import * as React from 'react';

/** (Replacement for previous typescript currency formatter)
 * Formats and displays the amount as currency (price).
 * @param amount [number=0] price/amount that is being converted to currency format.
 * @param currency [string=""] the currency label.
 * @returns {React.JSX.Element} amount formatted as currency in the form of HTML React Component.
 */
interface DisplayCurrencyFormattedProps {
    amount: number;
    currency?: string;
}

export const betterToFixed = (num: number) => (Math.round(num * 100) / 100).toFixed(2);

export const DisplayCurrencyFormatted = ({amount = 0, currency = ''}: DisplayCurrencyFormattedProps) => {
    if (amount === null) {
        return <>Pricing Error</>;
    }
    const formattedAmount = betterToFixed(amount)
        .replace(/(\d)(?=(\d\d\d)+(?!\d))/g, '$1,')
        .replace('-', '');

    return (
        <span className={amount < 0 ? 'tw-text-red-500' : ''}>
            {amount < 0 && '('}
            {currency.length !== 0 ? <>{currency}&nbsp;</> : '$'}
            {formattedAmount}
            {amount < 0 && ')'}
        </span>
    );
};
