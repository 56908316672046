import * as React from 'react';

import {CorpAllowance} from '../promos/promo.class';
import {DisplayCurrencyFormatted} from '../orders/display-currency-formatted/DisplayCurrencyFormatted';
import {TemplateHelpers} from '../tools/template-helpers.class';

export interface CorpAllowanceWidgetProps {
    corpAllowance: CorpAllowance;
}
export const CorpAllowanceWidget = ({corpAllowance}: CorpAllowanceWidgetProps) => {
    if (!corpAllowance) {
        return <></>;
    }
    return (
        <div className="tw-relative tw-flex tw-flex-col tw-min-w-0 tw-bg-white tw-border tw-rounded-sm tw-h-full">
            <div className="first:tw-rounded-tr-[1px] first:tw-rounded-tl-[1px] tw-py-3 tw-mb-0 tw-border-b !tw-bg-transparent tw-flex tw-justify-between tw-items-center !tw-px-2">
                <h4 className="!tw-m-0">{corpAllowance.allowanceDesc}</h4>
            </div>
            <div className="tw-p-4">
                <div className="tw-mb-8">
                    <img
                        alt={corpAllowance.allowanceDesc}
                        className="tw-max-w-full tw-h-auto"
                        height="230"
                        src={`/dimg/${corpAllowance.webImage}`}
                        width="712"
                    />
                </div>
                {!corpAllowance.thankYouMsg || corpAllowance.remainingBal > 0 ? (
                    <>
                        <div>
                            <span className="tw-font-bold">Card Number: </span>
                            {corpAllowance.allowancePcode}
                        </div>
                        <div>
                            <span className="tw-font-bold">Balance: </span>
                            <DisplayCurrencyFormatted amount={corpAllowance.remainingBal} />
                        </div>
                        {corpAllowance.lastUpdate && (
                            <div>
                                <span className="tw-font-bold">Last Updated: </span>
                                {TemplateHelpers.formatDate(corpAllowance.lastUpdate)}
                            </div>
                        )}
                        {corpAllowance.expDate && (
                            <div>
                                <span className="tw-font-bold">Expiration Date: </span>
                                {TemplateHelpers.formatDate(corpAllowance.expDate)}
                            </div>
                        )}
                    </>
                ) : (
                    <div dangerouslySetInnerHTML={{__html: corpAllowance.thankYouMsg}} />
                )}
            </div>
        </div>
    );
};
