import * as React from 'react';
import styled from '@emotion/styled';

import {Slice} from '../cms/cms.types';
import PromotionTracker from '../analytics/promotion-tracker/PromotionTracker';

const Zone4Style = styled.div`
    .zone4_aspect_wrap {
        position: relative;
        width: 100%;
        height: 0;
        padding-top: calc(150 / 780 * 100%);
        @media (max-width: 1025px) {
            padding-top: calc(200 / 1024 * 100%);
        }
        @media (max-width: 480px) {
            padding-top: calc(180 / 480 * 100%);
        }

        picture {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
        }
    }
`;

export interface Zone4Props {
    zone_4_banners: Slice;
}

export const Zone4 = ({zone_4_banners}: Zone4Props) => {
    if (!zone_4_banners) {
        return <></>;
    }
    return (
        <Zone4Style>
            <div
                className={`tw-mb-8 tw-px-4 tw-bg-gray-100 tw-pt-6 lg:tw-grid tw-grid-cols-${
                    zone_4_banners.slice_label === '2_wide' ? '2' : '1'
                } tw-gap-4`}
            >
                {zone_4_banners.items.map((zone4Banners, index) => (
                    <PromotionTracker
                        classes="tw-mb-6"
                        key={index}
                        promotion={{
                            creative_name: zone4Banners.ga_promotion_creative,
                            creative_slot: index + 1,
                            promotion_id: zone4Banners.ga_promotion_id,
                            promotion_name: zone4Banners.ga_promotion_name,
                        }}
                    >
                        <a href={zone4Banners.banner_link}>
                            <div className="tw-relative tw-w-full tw-h-0 tw-pt-[calc(180/480*100%)] sm:tw-pt-[calc(200/1024*100%)] lg:tw-pt-[calc(150/780*100%)]">
                                <picture className="tw-absolute tw-w-full tw-h-full tw-top-0 tw-left-0">
                                    <source
                                        media="(max-width: 480px)"
                                        srcSet={zone4Banners.banner_image.small?.url || zone4Banners.banner_image.url}
                                    />
                                    <source
                                        media="(max-width: 1024px)"
                                        srcSet={zone4Banners.banner_image.medium?.url || zone4Banners.banner_image.url}
                                    />
                                    <img
                                        alt={zone4Banners.banner_image.alt}
                                        className="tw-w-full tw-border"
                                        src={zone4Banners.banner_image.url}
                                    />
                                </picture>
                            </div>
                        </a>
                    </PromotionTracker>
                ))}
            </div>
        </Zone4Style>
    );
};
