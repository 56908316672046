import * as React from 'react';

import useRecommendations from '../items/item-spinner/use-recommendations';
import {ItemSpinner} from '../items/item-spinner/ItemSpinner';
import {MonetateService} from '../../client/monetate/monetate.service';
import {User} from '../users/user.class';
import {useService} from '../react/ServiceContext';

interface HomeRecoSpinnerProps {
    user: User;
}

export const HomeRecoSpinner = ({user}: HomeRecoSpinnerProps) => {
    const componentName = `HomeRecoSpinner`;
    const monetateService: MonetateService = useService(`monetateService`);
    const recommendations = useRecommendations(
        {
            scheme: `homeuser1_rr`,
        },
        monetateService,
    );

    /**
     * Template
     */
    return (
        <div className="tw-p-4 md:tw-p-0 tw-mt-4 md:tw-mt-0 lg:tw-mb-4 tw-bg-white">
            {recommendations.items?.length > 0 && (
                <div id="reactSpinner">
                    <ItemSpinner
                        componentName={componentName}
                        gaItemListName={`scheme_${recommendations.schemeName}`}
                        label={recommendations.schemeExplanation}
                        loaded={recommendations.loaded}
                        maxSlides={7}
                        orderableItems={recommendations.items}
                        useAddToOrderModal={true}
                        user={user}
                    />
                </div>
            )}
        </div>
    );
};
