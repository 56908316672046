import * as React from 'react';
import {PrismicRichText} from '@prismicio/react';

import PromotionTracker from '../../analytics/promotion-tracker/PromotionTracker';
import {HeroBannerButton} from './HeroBannerButton';
import {HeroBannerSlice} from '../cms.types';

export interface HeroBannerProps {
    slice: HeroBannerSlice;
}

export const HeroBanner = ({slice}: HeroBannerProps) => (
    <div className="tw-block tw-mb-6 md:tw-mb-0 lg:tw-mb-10">
        {slice?.primary?.rich_text_overlay.length > 0 && (
            <div className="tw-relative">
                <div className="tw-border tw-z-10 lg:tw-border-none tw-w-full lg:tw-w-1/2 tw-relative lg:tw-absolute tw-bg-white !tw-p-6 lg:tw-ml-10 tw-mb-8 lg:tw-mb-0 tw-z-1">
                    <PrismicRichText field={slice.primary.rich_text_overlay} />
                    {slice.items.map(
                        (heroBannerButtonData, index) =>
                            heroBannerButtonData.button_title &&
                            (heroBannerButtonData.promotion_id ? (
                                <PromotionTracker
                                    as="span"
                                    key={index}
                                    promotion={{
                                        creative_name: heroBannerButtonData.promotion_creative,
                                        creative_slot: index + 1,
                                        promotion_id: heroBannerButtonData.promotion_id,
                                        promotion_name: heroBannerButtonData.promotion_name,
                                    }}
                                >
                                    <HeroBannerButton heroBannerButtonData={heroBannerButtonData} />
                                </PromotionTracker>
                            ) : (
                                <HeroBannerButton
                                    heroBannerButtonData={heroBannerButtonData}
                                    key={index}
                                />
                            )),
                    )}
                </div>
            </div>
        )}
        <div className="tw-relative tw-w-full tw-h-0 tw-pt-[calc(180/480*100%)] sm:tw-pt-[calc(330/1400*100%)]">
            <picture className="tw-absolute tw-w-full tw-h-full tw-top-0 tw-left-0">
                <source
                    media="(max-width: 480px)"
                    srcSet={slice.primary.banner_background_image.small.url || slice.primary.banner_background_image.url}
                />
                <source
                    media="(max-width: 1025px)"
                    srcSet={slice.primary.banner_background_image.url}
                />
                <img
                    alt={slice.primary.banner_background_image.alt}
                    className="tw-w-full tw-max-w-full tw-h-auto"
                    height={slice.primary.banner_background_image.dimensions.height}
                    src={slice.primary.banner_background_image.url}
                    width={slice.primary.banner_background_image.dimensions.width}
                />
            </picture>
        </div>
    </div>
);
