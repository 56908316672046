import * as React from 'react';
import {useEffect, useState} from 'react';
import {zonedTimeToUtc} from 'date-fns-tz';

import {Alerts} from '../ui/Alerts/Alerts';
import {FetchService} from '../../client/fetch/fetch.service';
import {GaItemListName} from '../../client/ga/ga-ecommerce.functions';
import {gaLegacyCustomEvent} from '../../client/ga/ga-legacy.functions';
import {GetShipSaverTotalRes} from '../reports/report.types';
import {ItemToAdd} from '../order-items/order-items.class';
import {OrderItemsWorkflow} from '../../client/order-items/order-items.workflow';
import {ReportsService} from '../../client/reports/reports.service';
import {SHIPPING_SAVER} from '../items/item.class';
import {User} from '../users/user.class';
import {useService} from '../react/ServiceContext';
import Button from '../ui/Buttons/Button';
import {PageHeader} from '../ui/PageHeader/PageHeader';

interface ShippingSaverWidgetProps {
    user: User;
}

export const ShippingSaverWidget = ({user}: ShippingSaverWidgetProps) => {
    const [expirationDate, setExpirationDate] = useState(``);
    const [expired, setExpired] = useState(false);
    const [shipSaverTotal, setShipSaverTotal] = useState<GetShipSaverTotalRes>();
    const componentName = `ship-saver-component`;
    const fetchService: FetchService = useService(`fetchService`);
    const gaItemListName: GaItemListName = `ShippingSaverWidget`;
    const orderItemsWorkflow: OrderItemsWorkflow = useService(`orderItemsWorkflow`);
    const reportsService: ReportsService = new ReportsService(fetchService);

    // getShipSaverTotal
    useEffect(() => {
        const MIN_SAVER_VALUE = parseInt(user.getShipSaverAmt()) || 99;
        if (user.showShipSaverWidget()) {
            reportsService
                .getShipSaverTotal()
                .then((getShipSaverTotalRes) => {
                    if (getShipSaverTotalRes.result === `OK` && getShipSaverTotalRes.amountSaved > MIN_SAVER_VALUE) {
                        if (Date.now() > Date.parse(user.shippingSaver)) {
                            setExpired(true);
                        }
                        setExpirationDate(
                            zonedTimeToUtc(user.shippingSaver, `America/Chicago`).toLocaleDateString(`en-US`, {
                                month: `2-digit`,
                                day: `2-digit`,
                                year: `numeric`,
                            }),
                        );
                        setShipSaverTotal(getShipSaverTotalRes);
                    }
                })
                .catch(() => {
                    // Error silently
                });
        }
        // Only run once per page load
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /**
     * TBD
     */
    const addShippingSaverToOrder = () => {
        const shippingSaverItem: ItemToAdd = {
            gaEcommerce: {item_list_name: gaItemListName},
            item: SHIPPING_SAVER,
            unitsOrdered: 1,
        };
        gaLegacyCustomEvent({eventAction: `Add to Order Clicks`, eventCategory: `Ecommerce`, eventLabel: componentName});
        orderItemsWorkflow.addToOrderModal([shippingSaverItem], componentName, false);
    };

    /**
     * Template
     */
    if (!shipSaverTotal) {
        return <></>;
    }
    return (
        <div className="tw-mb-6">
            <PageHeader
                title="Shipping Saver"
                type="h4"
            />
            <div className="tw-relative tw-flex tw-flex-col tw-min-w-0 tw-border tw-bg-white tw-rounded-sm tw-mt-3">
                {expired && (
                    <Alerts
                        message={`Your Shipping Saver Membership expired on ${expirationDate}`}
                        variant="warning"
                    />
                )}
                <div className="tw-p-4 tw-text-center">
                    <h5 className="tw-pt-4">Total Savings</h5>
                    <h1>${shipSaverTotal.amountSaved}</h1>
                    <h5>Enrolled Since {shipSaverTotal.startDate}</h5>
                    {expired ? (
                        <>
                            <Button
                                className="tw-mt-3"
                                onClick={() => {
                                    addShippingSaverToOrder();
                                }}
                                size="sm"
                                variant="outline-secondary"
                            >
                                Renew Membership
                            </Button>
                            <Button
                                className="tw-mt-3 !tw-px-4 !tw-block"
                                href="/item/8000000"
                                size="sm"
                                variant="link"
                            >
                                Learn More
                            </Button>
                        </>
                    ) : (
                        <div className="tw-mt-3 tw-pb-4">Expires {expirationDate}</div>
                    )}
                </div>
            </div>
        </div>
    );
};
