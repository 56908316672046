import * as React from 'react';
import {faPhone, faEnvelope, faMegaphone} from '@fortawesome/pro-solid-svg-icons';

import {gaLegacyDataLayerPush} from '../../client/ga/ga-legacy.functions';
import {MobileTile} from './MobileTile';
import {Rep} from '../users/user.types';

interface ContactTilesProps {
    reps: Rep[];
}

export const ContactTiles = ({reps}: ContactTilesProps) => {
    return (
        <>
            <MobileTile
                href="tel:18005582808"
                icon={faPhone}
                label="Call Imperial Supplies"
            />
            {reps[0] && (
                <MobileTile
                    href={`mailto:${reps[0].mailto}`}
                    icon={faEnvelope}
                    label="Email Dedicated Account Advisor"
                    subLabel={`Send an email to ${reps[0].name}`}
                />
            )}
            <MobileTile
                icon={faMegaphone}
                label="Submit Feedback"
                onClick={() => {
                    gaLegacyDataLayerPush(`injectQualtricsScript`);
                }}
            />
        </>
    );
};
