import * as React from 'react';

import {HeroBannerButtonData, PrismicItem} from '../cms.types';
import Button from '../../ui/Buttons/Button';
import {convertButtonTypeToVariant} from '../cms.functions';

interface HeroBannerButtonProps {
    heroBannerButtonData: HeroBannerButtonData | PrismicItem;
}

export const HeroBannerButton = ({heroBannerButtonData}: HeroBannerButtonProps) => (
    <Button
        className={`tw-mr-2 !tw-w-auto md:!tw-pt-2`}
        href={heroBannerButtonData.button_link}
        target={heroBannerButtonData.target && '_blank'}
        size="smToLg"
        variant={convertButtonTypeToVariant(heroBannerButtonData.button_type)}
    >
        {heroBannerButtonData.button_title}
    </Button>
);
